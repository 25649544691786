/*
  ____                _                                         _
 | __ )   __ _   ___ | | __ __ _  _ __  ___   _   _  _ __    __| |
 |  _ \  / _` | / __|| |/ // _` || '__|/ _ \ | | | || '_ \  / _` |
 | |_) || (_| || (__ |   <| (_| || |  | (_) || |_| || | | || (_| |
 |____/  \__,_| \___||_|\_\\__, ||_|   \___/  \__,_||_| |_| \__,_|
                           |___/
*/

.AboutGridLayer {
    position: relative;
    height: 200vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 50px auto 60% 50px;
    grid-template-rows: 30px 5% 6% 17.5% 14% 3.5% auto 50%;
    gap: 1px 1px;
    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". SubTitleText SubTitleText ." ". Arrow Arrow ." ". . . ." "AboutBody AboutBody AboutBody AboutBody";
    z-index: 5;
    padding-bottom: 100px;
}

.AboutAccentLayer {
    top: 0;
    height: 100%;
    width: 100vw;
    background: #385646;
    opacity: .1;
    z-index: 1;
}

.AboutBodyPage {
    grid-area: AboutBody;
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(163, 196, 188, 0.5);
    display: grid;
    grid-template-columns: 4% 92% 4%;
    grid-template-rows: 5% 87% 8%;
    gap: 1px 1px;
    grid-template-areas: ". . ." ". AboutPageMain ." ". . .";
}

.AboutPageMain {
    grid-area: AboutPageMain;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
}

.AboutVisual {
    flex-grow: 1;
    position: relative;
}

.About_1 {
    opacity: .99;
    animation: fade-out 20s ease-in-out infinite;
}

.About_2 {
    opacity: 0;
    animation: fade-in 20s ease-in-out infinite;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    45% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.AboutImages {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.AboutText {
    height: 100%;
    max-width: 40%;
    min-width: 33%;
    width: fit-content;
    padding-right: 3%;
    overflow: auto;
    position: relative;
}

.AboutTextBody {
    font-size: 19px;
    color: #385646;
    line-height: 1.6;
    font-family: Roboto-regular, sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: fit-content;
    max-height: 100%;
}


/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */
.AboutGridLayer > .PageTitle {
    height: 63%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.AboutGridLayer > .PageSubTitle {
    color: rgba(255, 255, 255, 0.9);
    /*border: 3px solid rgba(255, 255, 255, 0.8);*/
    background: rgba(56, 86, 70, 0.9);
    font-size: 2.5vw;
    bottom: 5%;
}

.AboutGridLayer > .PageTitle > #about_letters {
    fill: #385646;
}


.AboutGridLayer > .PageTitle:hover #about_letters {
    /*font-family: Roboto-regular, sans-serif;*/
    /*fill: rgba(255, 255, 255, 0.42);*/
    stroke: #385646;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
    animation: letters_transition_color 2s ease forwards;
    animation-delay: 1s;
    /*animation-play-state: running;*/
}

@keyframes letters_transition_color {
    to {
        fill: rgba(0, 21, 49, 0.1);
        stroke: white;
    }
}

#about_letters path:nth-child(1) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1038;
}

#about_letters path:nth-child(2) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1354;
}

#about_letters path:nth-child(3) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1118;
}

#about_letters path:nth-child(4) {
     stroke-dasharray: 300;
     stroke-dashoffset: 970;
 }

#about_letters path:nth-child(5) {
    stroke-dasharray: 300;
    stroke-dashoffset: 708;
}

#about_letters path:nth-child(6) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#about_letters path:nth-child(7) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1119;
}

@media only screen and (max-width: 800px) {
    .AboutGridLayer {
        width: 100vw;
        height: 200vh;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 15% 4.5% 7.5% 2.5% 10% 10.5% 50%;*/
        grid-template-rows: 20% 5% 10% 2.5% 10% 2.5% 50%;
        gap: 1px 1px;
        grid-template-areas: ". . ." "TitleText TitleText TitleText" ". SubTitleText ."  ". . ." ". NavBar ." ". . ." "AboutBody AboutBody AboutBody";
    }

    .AboutGridLayer > .PageTitle {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    .AboutGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2.4vh;
    }

    .AboutPageMain {
        flex-direction: column-reverse;
        padding: 1.3vh;
        border-radius: 1.3vh;
        background: rgba(255, 255, 255, 0.5);
        /*background-image: linear-gradient(to bottom right, rgba(163, 196, 188, .72), rgba(255, 255, 255, .64));*/
    }

    .AboutBodyPage {
        width: 100vw;
        height: 100vh;
        grid-template-columns: 5% 90% 5%;
        grid-template-rows: 3% 92% 5%;
    }

    .AboutText {
        height: 60%;
        max-height: 70%;
        min-height: 33%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-right: 0;
    }

    .AboutTextBody {
        font-size: 16px;
    }
}